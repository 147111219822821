<template>
    <div class="text-center text-danger my-2">
        <b-spinner class="align-middle"></b-spinner>
    </div>
</template>

<script>
    export default {
        name: "b-v-spinner"
    }
</script>

<style scoped>

</style>