<template>
    <div>
        <h1>Ошибка 404</h1>
    </div>
</template>

<script>
    export default {
        name: "v-404"
    }
</script>

<style scoped>

</style>