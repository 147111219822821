<template>
    <transition name="slide">
    <div><h1>Home</h1></div>
    </transition>
</template>

<script>
    export default {
        name: "v-home"
    }
</script>

<style>

</style>