<template>
    <div id="app">
        <transition>
                <router-view></router-view>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "App",
    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">

</style>